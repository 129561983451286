<template>
  <div class="mainContant">
    <van-nav-bar @click-left="leftReturn" title="选择部门"  left-arrow fixed/>
    <div class="searchDiv">
        <van-search v-model="searchName" class="serch" placeholder="请输入部门关键词" @search="initData"/>
    </div>
        <!-- <div style="height:55px;width: 55px; background: #fff" class="centerStyle">
            <van-icon @click="backLeft" color="red" name="revoke" />
        </div> -->
    <div class="tabBarContant nowChoose" style="margin-left:20px;">
        部门:<span style="margin-right:5px;" v-for="it,ins in showTextArr" :key="ins" @click="chooseDepartment(it,(ins-1))"> {{it.label + ' /'}}</span>
    </div>
    <div class="listContant">
        <van-radio-group v-model="radio">
            <van-cell-group v-for="item,index in cellList" :key="index">
            <van-cell is-link>
                <template #title>
                    <div style="display:flex">
                        <van-radio @click="radioLabel(item)" :name="item.id" v-if="item.level != 1"/>
                        <span class="custom-title">{{item.title}}</span>
                    </div>
                </template>
                <template #right-icon>
                    <div v-if="item.children" @click="chooseNextDom(item)" class="centerStyle">
                        <van-icon color="#1576D7" name="bars" />
                        <!-- <span class="nowChoose">下级</span> -->
                    </div>
                </template>
            </van-cell>
            </van-cell-group>
        </van-radio-group>

    </div>
    <div class="footSlot">
        <div class="justyStyle">
            <div class="nowChoose">当前选择：
                <span style="margin-right:5px" >{{showText}}</span>    
            </div>
            <div class="saveBtn centerStyle" @click="saveEvent">确认</div>
        </div>
    </div>
  </div>
</template>

<script>
import { NavBar, Search, Icon, Area, Col, Row, Cell, CellGroup, RadioGroup, Radio, Notify  } from 'vant';
import { queryDepartmentTreeList, browseDepartmentList } from "../../../getData/getData";
import { getStaffId, responseUtil } from "../../../libs/rongxunUtil";
import eventBus from "../../../components/rongxun/rx-navBar/envbus.js"

export default {
    components: {
        [NavBar .name]:NavBar,
        [Search .name]:Search,
        [Icon .name]:Icon,
        [Area .name]:Area,
        [Col .name]:Col,
        [Row .name]:Row,
        [Cell .name]:Cell,
        [CellGroup .name]:CellGroup,
        [RadioGroup .name]:RadioGroup,
        [Radio .name]:Radio,
    },
    data() {
        return {
            searchName: '',
            cellList: [],
            radio: '',
            showText: '暂无',
            showTextArr: [],
            chooseNextId: [],
            allCellList: []
        }
    },
    activated() {
        this.searchName = ''
        this.showTextArr = []
        this.chooseNextId= []
        if(this.$route.query.selectId) {
            this.radio = this.$route.query.selectId
        } else {
            this.radio = ''
        }
        if(this.$route.query.selectName) {
            this.showText = this.$route.query.selectName
        } else {
            this.showText = '暂无'
        }
        this.initqueryDepartmentTreeList()
    },
    mounted() {
        
    },
    methods: {
        leftReturn() {
            
            this.$router.go(-1);
        },
        initData() {
            const id = ''
            this.queryBrowseDepartmentLis(id,this.searchName)
        },
        chooseNextDom(item) {
            // let length = this.showTextArr.length ? this.showTextArr.length : 0
            // if(length >= 3) {
            //     this.showTextArr.shift()
            // }
            let attrs = {
                label: item.title,
                id: item.id
            }
            this.chooseNextId.push(item.id)
            this.showTextArr.push(attrs)
            this.cellList = item.children
        },
        initqueryDepartmentTreeList(idInfo = '') {
            var that = this
            const params = {
                id: idInfo,
            }
            queryDepartmentTreeList(params).then(function (response) {
                responseUtil.dealResponse(that, response, () => {
                    that.cellList = response.data.data.departmentList
                    that.allCellList = response.data.data.departmentList
                    console.log(that.cellList,"that.cellList=====")
                })
            })
        },
        
        radioLabel(e) {
            // let length = this.showTextArr.length ? this.showTextArr.length : 0
            // if(length >= 3) {
            //     this.showTextArr.shift()
            // }
            // this.showText = e.title
            // this.showTextArr = []
            // let radios = {
            //     label: e.title,
            //     id: e.id
            // }
            // this.showTextArr.push(radios)
            this.showText = e.title
        },
        
        saveEvent() {
            let params = {
                selectId: this.radio,
                selectName: this.showText
            }
            eventBus.$emit('chooseDepartment',params);
            this.leftReturn()
        },
        backLeft() {
            let length = this.chooseNextId.length ? this.chooseNextId.length : 0
            if(length == 0) {
                Notify('已经是第一层了,无法返回上一级了!');
                return
            }
            if(length >= 2) {
                var backId = this.chooseNextId[this.chooseNextId.length - 2]
            } else {
                var backId = 'ROOT'
            }
            this.chooseNextId.pop()
            this.queryBrowseDepartmentLis(backId)
            
        },
        queryBrowseDepartmentLis(bakid,name = '') {
            var that = this
            const params = {
                departmentStatus: '0',
                currentPage: 1,
                numberPage: 500,
                user_id: getStaffId(),
                id: bakid,
                departmentName: name
            }
            console.log(params, "params======")
            browseDepartmentList(params).then(function (response) {
                responseUtil.dealResponse(this, response, () => {
                    that.cellList = response.data.data.data
                })
            })

        },
        chooseDepartment(item,index) {
            console.log(this.showTextArr,this.chooseNextId)
            
            console.log(item,index,"dasdsadsadsadsad====")
            var backId = this.chooseNextId[index]
            this.showTextArr.splice(index + 1,this.showTextArr.length - index)
            this.chooseNextId.splice(index + 1,this.chooseNextId.length - index)
            console.log(backId,this.showTextArr,this.chooseNextId)
            this.initqueryDepartmentTreeList(backId)
            // let length = this.chooseNextId.length ? this.chooseNextId.length : 0
            // console.log(this.chooseNextId[index])
            // if(length >= 1) {
            //     var backId = this.chooseNextId[index]

            //     this.showTextArr.splice(index,this.showTextArr.length - (index-1))
            //     this.chooseNextId.splice(index,this.chooseNextId.length - (index-1))
            //     console.log(backId,"backId====")
            //     console.log(this.showTextArr,"showTextArr====")
            //     console.log(this.chooseNextId,"this.chooseNextId====")
            //     alert(1111)
            //     // this.queryBrowseDepartmentLis(backId)
            // } else {
            //     alert(222)
            //     // this.initqueryDepartmentTreeList()
            //     // var backId = 'ROOT'
            // }
        },

         
    }
}
</script>

<style scoped lang="less">
.mainContant {
    height: 100vh;
    background: #f8f8f8;
    overflow: auto;
    position: relative;
}
.searchDiv {
    margin-top: 60px;
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .serch{
        width: 100%;
        margin: 0 auto;
    }
}
.listContant {
    margin-top: 2px;
}
.custom-title {
    margin-left: 10px;
}
.centerStyle {
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.footSlot {
    position: fixed;
    font-size: 16px;
    bottom: 0px;
    height: 60px;
    line-height: 60px;
    width: 100%;
    background: #fff;
    border-top: 1px solid #f8f8f8;
}
.justyStyle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 20px;
}
.saveBtn {
    width: 70px;
    color: #fff;
    font-size: 14px;
    height: 35px;
    border-radius: 5px;
    background-image: linear-gradient(to right, #ffc274, #ff5d3b);
}
.nowChoose {
    font-size: 14px;
    color: #1576D7;
}
.tabBarContant {
    height: 50px;
    background: #fff;
    // margin-top: 9px;
    // margin-top: 60px;
    display: flex;
    align-items: center;
    width: 100vw;
    // overflow:hidden;
    white-space:nowrap;
    overflow-x: scroll;
    // padding-left: 10px;
}
</style>